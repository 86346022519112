@tailwind base;
@tailwind components;
@tailwind utilities;

 
/* scrollbar */
::-webkit-scrollbar {
    width: 10px;
    height: 5px !important;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #fff; 
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #33598f; 
    height: 10px;
    padding: 25px;
    border-radius: 10px;
  }
  
  /* scrollbar */